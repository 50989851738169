import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import './Overview.css'; // Create this CSS file for styling

const Overview = () => {
  return (
    <Box className="overview-section">
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 2 , marginBottom:'30%'}}>
        <Typography variant="h3" gutterBottom>
          Overview
        </Typography>
        <Typography variant="body1" paragraph>
        <p>
          Foodcoast International is a unit of prestigious ISO 9001: 2000 & ISO 14000 certified HR Group of Industries. The company manufactures Indian flavored processed food with a scrumptious range of Sauces, Dips, Mayonnaise, Gravies, Toppings & Crushes. World- class machinery, finest quality control policy, robust food production technology and dedicated workforce are the strongest pillars behind its success code.
          </p><p>
Foodcoast operates its business with 9 depots and more than 500 prominent distributors PAN India under Brand Name FOODCOAST & FC Plus in Indian market. We have strong presence in HoReCa segment where our products are widely available at leading National, Regional & local foodservice chains. We are currently committed with esteemed brands like:- Dominos, Hongs Kitchen, Chefboss, IKEA, Parsuma, Cornitos, Luv It and many reputed regional players active in B2B industry. We are exporting our complete range of products in private label to UK, Singapore, Hong Kong, Australia, Liberia, Sierra Leone, UAE, Iraq & Nepal. *Foodcoast is also Co-Manufacturer for Nestle and Dabur.
</p>
        </Typography>
      </Container>
    </Box>
  );
};

export default Overview;

  