import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Sustainability.css'; // Create and include a CSS file for custom styles

const Sustainability = () => {
  return (
    <>
      {/* Breadcrumbs Section */}
      <section className="breadcrumbs-custom" style={{ marginBottom: '5em' }}>
        <div
          className="parallax-container"
          style={{ backgroundImage: "url('images/slide-03.jpg')" }}
        >
          <div className="breadcrumbs-custom-body parallax-content context-dark">
            <Container  style={{ color: '#333' }}>
              <h2 className="breadcrumbs-custom-title">Sustainability</h2>
              <h5 className="breadcrumbs-custom-text">
                Foodcoast International - Authentic Freshness of Flavour
              </h5>
            </Container>
          </div>
        </div>
        <div className="breadcrumbs-custom-footer">
          <Container>
            <ul className="breadcrumbs-custom-path">
              <li>
                <a href="../">Home</a>
              </li>
              <li className="active">Sustainability</li>
            </ul>
          </Container>
        </div>
      </section>

      {/* About Us Section 1 */}
      <section className="section bg-image-1 text-md-start" style={{ padding: "50px 0" }}>
        <Container>
        <h2 className="text-center mb-4">Sustainability</h2>
          <Row className="row-40 row-md-60 justify-content-center align-items-xl-center">
            <Col md={11} lg={8} xl={8}>
              <article className="quote-classic-big inset-xl-right-30">
                <p>
                  The time for action is here. Climate change, choking of our water bodies, and soil
                  degradation—all of these are outcomes of human exploitation over the last few
                  centuries—and need to be reversed fast, or we risk destroying our blue planet forever.
                </p>
                <p>
                  Our deep commitment to preserving our wonderful planet and all of its biodiversity is
                  a natural extension of our philosophy of innovation and maintaining high quality
                  standards in the food industry.
                </p>
                <p>
                  We are collecting and repurposing more plastic each year than we add to our environment,
                  thus ensuring that we help maintain the delicate balance in nature.
                </p>
                <p>
                  All the plastic that we use is recyclable, and we have partnered with ReKart to ensure
                  transparent and responsible processing of all the plastic collected.
                </p>
              </article>
            </Col>
            <Col md={11} lg={4} xl={4}>
              <div className="slick-slider-1 inset-xl-left-35">
                <img
                  src="images/GIf_02.webp"
                  alt="Sustainability Visual"
                  width="634"
                  height="373"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* About Us Section 2 */}
      <section className="section text-md-start" style={{ background: "#ddeef6" }}>
        <Container>
          <Row className="row-40 row-md-60 justify-content-center align-items-xl-center">
            <Col md={11} lg={4} xl={4}>
              <div className="slick-slider-1 inset-xl-left-35">
                <img
                  src="images/banner.webp"
                  alt="Sustainability Visual"
                  width="634"
                  height="373"
                />
              </div>
            </Col>
            <Col md={11} lg={8} xl={8}>
              <article className="quote-classic-big inset-xl-right-30">
                <h4>
                  Our efforts are currently aimed at collecting plastic in all forms, ensuring that they
                  are processed and repurposed into a usable form with minimal impact on the environment.
                </h4>
              </article>
            </Col>
          </Row>
        </Container>
      </section>

      {/* How We Do It Section */}
      <section className="section bg-image-1 text-md-start" style={{ padding: "50px 0" }}>
        <Container>
          <center>
            <h3>How do we do it?</h3>
          </center>
          <Row className="row-40 row-md-60 justify-content-center align-items-xl-center">
            {[
              {
                img: "images/001-1668768361792.webp",
                title: "Minimize",
                description:
                  "We use the minimum amount of plastic to safely deliver our products across the country.",
              },
              {
                img: "images/003-1668768377512.webp",
                title: "Remove",
                description:
                  "We prevent plastic from piling up in our landfills and eroding the environment.",
              },
              {
                img: "images/002-1668768395607.gif",
                title: "Reduce",
                description:
                  "We repurpose more plastic than we consume, thereby reducing our plastic footprint.",
              },
              {
                img: "images/004-1668768411590.webp",
                title: "Partner",
                description:
                  "We collaborate with ReKart, a member of MRAI* and AIPMA** to be plastic positive.",
              },
            ].map((item, index) => (
              <Col md={11} lg={3} xl={3} key={index}>
                <div style={{ textAlign: "center" }}>
                  <img src={item.img} alt={item.title} />
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Sustainability;
