import React from 'react';
import Slider from 'react-slick';
import { Container, Image } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ClientNetworkSection.css';

const images = [
  'images/brands/burger-king-logo.png',
  'images/brands/ChefBoss-Logo.png',
  'images/brands/cropped-logo-6.png',
  'images/brands/dominos-logo.png',
  'images/brands/haldirams.png',
  'images/brands/hong_logo2.png',
  'images/brands/ikea.png',
  'images/brands/luvit.png',
  'images/brands/PRASUMA_Logo.jpg',
  'images/brands/rebel-foods.png'
];

const ClientNetworkSection = () => {
  const settings = {
    dots: false, // Remove pagination dots
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (

    <Container fluid className="my-4">  <h2 className="wow fadeScale">
    PAN India & Global<br />
    Client Network
  </h2>
      <Slider {...settings}>
        {images.map((image, idx) => (
          <div key={idx} className="carousel-cn-image-col">
            <Image src={image} alt={`Category ${idx + 1}`} className="d-block w-100 category-cn-image" />
            <div className="overlay-cn-text">HELLO</div> {/* Overlay text */}
          </div>
        ))}
      </Slider>
    </Container>
  );
};

export default ClientNetworkSection;
