import React from 'react';
import CustomNavbar from '../Components/CustomNavbar';
import Sustainable from '../Components/Sustainability';
import Footer from '../Components/Footer';


const Sustainability = () => {
  return (
    <>
    <CustomNavbar/>
    <Sustainable/>
    <Footer/>  
    
    </>
  );
};

export default Sustainability;