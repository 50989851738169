import React from 'react';
import { Container } from 'react-bootstrap';
import '@mdi/font/css/materialdesignicons.min.css';  // Import Material Design Icons CSS
import './Footer.css';

const Footer = () => {
  return (
    <footer className="section footer-modern">
      <div className="footer-corporate-body section-lg">
        <Container>
          <div className="row row-40 row-md-50 justify-content-xl-between">
            {/* Contact Section */}
            <div className="col-sm-6 col-lg-4 wow fadeInRight">
              <h5 className="footer-corporate-title">Contact</h5>
              <div className="footer-corporate-decor"></div>
              <ul className="footer-corporate-info">
                <li>
                  <div className="unit flex-column flex-sm-row align-items-center">
                    <div className="unit-left">
                      <span className="icon mdi mdi-map-marker"></span>
                    </div>
                    <div className="unit-body">
                      <a href="#">
                        Foodcoast International
                        <br />
                        A-23A Focal Point Extension
                        <br />
                        Jalandhar - 144004 Punjab. INDIA
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="unit flex-column flex-sm-row align-items-center">
                    <div className="unit-left">
                      <span className="icon mdi mdi-phone"></span>
                    </div>
                    <div className="unit-body">
                      <ul className="list-schedule">
                        <li><span>1800 274 274 0 (Toll Free)</span></li>
                        <li><span>+91 181 5077771/72</span></li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="unit flex-column flex-sm-row align-items-center">
                    <div className="unit-left">
                      <span className="icon mdi mdi-email"></span>
                    </div>
                    <div className="unit-body">
                      <ul className="list-schedule">
                        <li><span>support@foodcoast.com</span></li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            {/* Quick Links Section */}
            <div className="col-sm-6 col-lg-4 wow fadeInRight" data-wow-delay=".1s">
              <h5 className="footer-corporate-title">Quick links</h5>
              <div className="footer-corporate-decor"></div>
              <ul className="footer-corporate-list d-sm-inline-block d-md-block">
                <li><a href="#">Home</a></li>
                <li><a href="#">About</a></li>
                <li><a href="#">Our Team</a></li>
                <li><a href="#">Products</a></li>
                <li><a href="#">Capability</a></li>
                <li><a href="#">Sustainability</a></li>
                <li><a href="#">Contact</a></li>
              </ul>
            </div>

            {/* Get in Touch Section */}
            <div className="col-lg-4 wow fadeInRight" data-wow-delay=".2s">
              <h5 className="footer-corporate-title">Get in touch</h5>
              <div className="footer-corporate-decor"></div>
              <p className="footer-corporate-text">
                We are always ready to answer any questions you may have.
              </p>
            </div>
          </div>
        </Container>
      </div>

      {/* Footer Panel */}
      <div className="footer-corporate-panel">
        <Container>
          <div className="row row-10 row-sm-30 align-items-center justify-content-sm-between">
            <div className="col-sm-auto col-lg-4 text-lg-start"></div>
            <div className="col-sm-auto col-lg-4">
              <ul className="list-inline list-social-4 list-inline-xs">
                <li><a className="icon mdi mdi-facebook icon-xxs" href="#"></a></li>
                <li><a className="icon mdi mdi-twitter icon-xxs" href="#"></a></li>
                <li><a className="icon mdi mdi-instagram icon-xxs" href="#"></a></li>
                <li><a className="icon mdi mdi-google-plus icon-xxs" href="#"></a></li>
                <li><a className="icon mdi mdi-skype icon-xxs" href="#"></a></li>
              </ul>
            </div>
            <div className="col-lg-4 text-lg-end">
  <p className="rights">
    <span>&copy;&nbsp;</span>
    <span className="copyright-year">{new Date().getFullYear()}</span>
    <span>&nbsp;</span>
    <span>Foodcoast International</span>
    <span>.&nbsp;All rights reserved.</span>
    <span>&nbsp;</span>
    <a href="#">Privacy Policy</a>
    <span>.</span>
  </p>
</div>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
