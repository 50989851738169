// src/components/ParallaxSection.js

import React from 'react';
import CountUp from 'react-countup';
import { Container, Row, Col } from 'react-bootstrap';
import './ParallaxSection.css'; // Make sure to create this CSS file
import desk from '../images/1455.jpg'; // Import the image

const ParallaxSection = () => {
  return (
    <div className="parallax bgimg">
      <Container fluid className="py-5">
        <Row className="text-center">
          <Col md={3}>
            <div className="counter-box">
              <h1 className="counter">
                <CountUp end={75} duration={2} />+
              </h1>
              <p className="pp">Years Since 1952</p>
            </div>
          </Col>
          <Col md={3}>
            <div className="counter-box">
              <h1 className="counter">
                <CountUp end={25000} duration={2} />+
              </h1>
              <p className="pp">Customers</p>
            </div>
          </Col>
          <Col md={3}>
            <div className="counter-box">
              <h1 className="counter">
                <CountUp end={1500} duration={2} />+
              </h1>
              <p className="pp">Employees</p>
            </div>
          </Col>
          <Col md={3}>
            <div className="counter-box">
              <h1 className="counter">
                <CountUp end={35} duration={2} />+
              </h1>
              <p className="pp">Products</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ParallaxSection;
