import React from 'react';
import './AboutUs.css'; // Include your custom CSS styles
 
import VisionMission from '../Components/VisionMission';

const AboutUs = () => {
  return (
    <>  
      {/* Breadcrumbs Section */}
      <section className="breadcrumbs-custom" style={{ marginBottom: '5em' }}>
        <div className="parallax-container" style={{ backgroundImage: `url(images/slide-03.jpg)` }}>
          <div className="breadcrumbs-custom-body parallax-content context-dark">
            <div className="container"  style={{ color: '#333' }}>
              <h2 className="breadcrumbs-custom-title">About Us</h2>
              <h5 className="breadcrumbs-custom-text">
                Foodcoast International - Authentic Freshness of Flavour
              </h5>
            </div>
          </div>
        </div>
        <div className="breadcrumbs-custom-footer">
          <div className="container">
            <ul className="breadcrumbs-custom-path">
              <li><a href="../">Home</a></li>
              <li className="active">About Us</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Promo Section */}
      <section className="section section-sm section-top-0 offset-negative-1">
        <div className="container">
          <article>
            <br />
           
            <h2 className="text-center mb-4">Company Profile</h2>
            <p className="big promo-classic-text">
              Foodcoast International is a unit of prestigious ISO 9001: 2000 & ISO 14000 certified HR Group of
              Industries. The company manufactures Indian flavored processed food with a scrumptious range of Sauces,
              Dips, Mayonnaise, Gravies, Toppings & Crushes. World-class machinery, finest quality control policy,
              robust food production technology, and dedicated workforce are the strongest pillars behind its success code.
            </p>
            <p className="big promo-classic-text">
              Foodcoast operates its business with 9 depots and more than 500 prominent distributors PAN India under
              Brand Name FOODCOAST & FC Plus in Indian market. We have strong presence in HoReCa segment where our
              products are widely available at leading National, Regional & local foodservice chains. We are currently
              committed with esteemed brands like:- Dominos, Hongs Kitchen, Chefboss, IKEA, Parsuma, Cornitos, Luv It
              and many reputed regional players active in B2B industry. We are exporting our complete range of
              products in private label to UK, Singapore, Hong Kong, Australia, Liberia, Sierra Leone, UAE, Iraq &
              Nepal. *Foodcoast is also Co-Manufacturer for Nestle and Dabur.
            </p>
          </article>

          <article style={{ width: '80%', marginLeft: '5%' }}>
            <br />
            <br />
            <span className="big promo-classic-text">
              <ul className="list-marked d-inline-block d-inline-md-block">
                <li>
                  Into Manufacturing of processed food products like Mayonnaise, Ketchups, Sandwich Spreads, Dips, and
                  Gravies, Crushes, and other ready-to-use products.
                </li>
                <li>
                  State of art Idea kitchen lab with Application tasting facility
                </li>
                <li>Catering to a global clientele.</li>
                <li>
                  Spread over a lavish 1,36,971sq feet, the company boasts of state-of-the-art machinery.
                </li>
                <li>
                  Latest & advanced equipment with technological advancements extending throughout the organization:
                  from R&D to production-warehousing, order processing to deliveries.
                </li>
                <li>
                  Foodcoast believes in - RIGHT IN FIRST TIME. To achieve this, we have our quality assurance and
                  quality control teams and well-equipped Chemical and Microbiological lab with the latest testing
                  equipment.
                </li>
              </ul>
            </span>
          </article>
        </div>
      </section><VisionMission/>
    
     
    </>
  );
};

export default AboutUs;
