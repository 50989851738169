import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './YummyliciousRecipes.css';

const YummyliciousRecipes = () => {
  const recipes = [
    {
      title: 'Tandoori Paneer Tikka',
      link: '#',
      videoSrc: 'https://www.youtube.com/embed/GJ375rc_y-M?si=KaVmehK_XWdlTZaL',
    },
    {
      title: 'Tandoori Roll Samosa',
      link: '#',
      videoSrc: 'https://www.youtube.com/embed/2GyLfaLHDEo?si=tJ8iwoab_NN5z7zN',
    },
    {
      title: 'Exotic Veggie Burger',
      link: '#',
      videoSrc: 'https://www.youtube.com/embed/W_l3t_IlxNs?si=-FDiT6rijkD4Ftvb',
    },
    {
      title: 'Parantha Tacos',
      link: '#',
      videoSrc: 'https://www.youtube.com/embed/CbBlGI826ts?si=8jABn26qilAypkLg',
    },
    {
      title: 'Exotic Paneer Salad',
      link: '#',
      videoSrc: 'https://www.youtube.com/embed/Q5Rh9YyFXac?si=gsnhGaNsD5AjdXVt',
    },
    {
      title: 'Mini Pizza Bites',
      link: '#',
      videoSrc: 'https://www.youtube.com/embed/0klRF-G2mnI?si=AxNa-69YcwcLPn-2',
    },
    {
      title: 'Vegetable Cheese Nachos',
      link: '#',
      videoSrc: 'https://www.youtube.com/embed/SWebdDYnH7M?si=U4lJ9L4DtbEshuL-',
    },
    {
      title: 'Healthy Veg Salad',
      link: '#',
      videoSrc: 'https://www.youtube.com/embed/yiZY5bvDgaE?si=Z-xTbIldDeYQyQNu',
    },
    {
      title: 'Veg Potato Lollipop',
      link: '#',
      videoSrc: 'https://www.youtube.com/embed/XgZu0DvucJk?si=Kg0CVcv9crXgiXtY',
    },
    {
      title: 'Rabokki Veg',
      link: '#',
      videoSrc: 'https://www.youtube.com/embed/KsEuzZVyfv0?si=qutqy-DkBeRIRDtX',
    },
    {
      title: 'Irani Paneer Roll',
      link: '#',
      videoSrc: 'https://www.youtube.com/embed/3jN1p8DcGak?si=iV3P5JTyVszFu7yf',
    },
    {
      title: 'Creamy Makhani Pasta',
      link: '#',
      videoSrc: 'https://www.youtube.com/embed/5WLFVQqJmM0?si=x8ZXxDAxAplJht7P',
    },
    {
      title: 'Macaroni Hot Dog',
      link: '#',
      videoSrc: 'https://www.youtube.com/embed/qbEzGvgWllU?si=wAhan08aqMX8Z8aD',
    },
  ];

  return (
    <section className="section section-sm section-last bg-image-1">
      <div className="container">
        <h2 className="wow fadeScale">Yummylicious Recipes</h2>
        <OwlCarousel
          className="owl-theme"
          items={5}
          margin={30}
          dots={true}
          mouseDrag={false}
          responsive={{
            0: { items: 1 },
            576: { items: 4 },
            992: { items: 5 },
          }}
        >
          {recipes.map((recipe, index) => (
            <article className="box-info-modern box-md wow slideInUp" key={index}>
              <iframe
                width="200"
                height="260"
                src={recipe.videoSrc}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                className="fluidvids-item"
              ></iframe>
              <h4 className="box-info-modern-title">
                <a href={recipe.link}>{recipe.title}</a>
              </h4>
              <div className="box-info-modern-text"></div>
              <a className="box-info-modern-link" href={recipe.link}>
                Read more
              </a>
            </article>
          ))}
        </OwlCarousel>
      </div>
    </section>
  );
};

export default YummyliciousRecipes;
