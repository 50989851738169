import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import AboutUs from './Pages/AboutUs';
import ContactUs from './Components/ContactForm';
import Sustainability from './Pages/Sustainability';
import Capability from './Pages/Capability';
import Nieu from './Pages/Nieu';
import Foodcoast from './Pages/Foodcoast';
import Mayonnaise from './Pages/Mayonnaise';
import TomatoKetchup from './Pages/TomatoKetchup';
import SandwichSpread from './Pages/SandwichSpread';
import SaladDressing from './Pages/SaladDressing';
import Dips from './Pages/Dips';
import ItalianSauces from './Pages/ItalianSauces';


function App() {
  return (
    <Router>
      <div className="App">
        {/* Navigation or other global components */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/sustainability" element={<Sustainability />} />
          <Route path="/capability" element={<Capability />} />
          <Route path="/nieu" element={<Nieu />} />
          <Route path="/foodcoast" element={<Foodcoast />} />
          <Route path="/tomato-ketchup" element={<TomatoKetchup />} />
          <Route path="/mayonnaise" element={<Mayonnaise />} />
          <Route path="/sandwich-spreads" element={<SandwichSpread />} />
          <Route path="/salad-dressings" element={<SaladDressing />} />
          <Route path="/dips" element={<Dips />} />
          <Route path="/italian-sauces" element={<ItalianSauces />} />
         
        </Routes>
      </div>
    </Router>
  );
}

export default App;