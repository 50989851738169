import React from 'react';
import CustomNavbar from '../Components/CustomNavbar';
import Saladdressing from '../Components/SaladDressing';
import Footer from '../Components/Footer';


const SaladDressing = () => {
  return (
    <>
    <CustomNavbar/>
    <Saladdressing/>
    <Footer/>  
    
    </>
  );
};

export default SaladDressing;