import React from 'react';
import CustomNavbar from '../Components/CustomNavbar';
import FoodCoasts from '../Components/RangeFoodcoast';
import Footer from '../Components/Footer';


const Foodcoast = () => {
  return (
    <>
    <CustomNavbar/>
    <FoodCoasts/>
    <Footer/>  
    
    </>
  );
};

export default Foodcoast;