import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';  // Use NavLink for active link management
import './Navbar.css';  // Make sure to add the CSS
import logo from '../images/foodcoast-logo.png';

const CustomNavbar = () => {
  return (
    <div className="custom-navbar-container">
      {/* First Row: Logo and Contact Button */}
      <Navbar bg="light" className="custom-navbar">
        <Container fluid className="px-4">
          <div className="navbar-top-row w-100 d-flex justify-content-between align-items-center">
            <Navbar.Brand href="/">
              <img
                src={logo}
                alt="Logo"
                className="navbar-logo"
                width="150"
              />
            </Navbar.Brand>
            <a href="/contact" className="btn btn-outline-danger navbar-contact">
              Contact Us
            </a>
          </div>
        </Container>
      </Navbar>

      {/* Second Row: Navigation Links */}
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px', marginLeft: '10%' }}
              navbarScroll
            >
              <Nav.Link as={NavLink} to="/" exact className="navbar-item" activeClassName="active" style={{ marginLeft: '20px' }}>
                Home
              </Nav.Link>
              <Nav.Link as={NavLink} to="/about" className="navbar-item" activeClassName="active" style={{ marginLeft: '20px' }}>
                About
              </Nav.Link>
              <NavDropdown title="BRANDS" id="navbarScrollingDropdown" style={{ marginLeft: '20px' }}>
                <NavDropdown.Item href="/foodcoast">FOODCOAST RANGE</NavDropdown.Item>
                 
                <NavDropdown.Divider />
                <NavDropdown.Item href="/nieu">NIEU RANGE</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={NavLink} to="/sustainability" className="navbar-item" activeClassName="active" style={{ marginLeft: '20px' }}>
              Sustainability
              </Nav.Link><Nav.Link as={NavLink} to="/capability" className="navbar-item" activeClassName="active" style={{ marginLeft: '20px' }}>
              Capability
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default CustomNavbar;
