import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Zoom } from 'react-awesome-reveal';
import VerifiedIcon from '@mui/icons-material/Verified';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SecurityIcon from '@mui/icons-material/Security';
import StarIcon from '@mui/icons-material/Star';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import whyUsImage from '../images/why-us-1.png'; // Import the image

import './WhyChooseUs.css';

const WhyChooseUs = () => {
  return (
    <Container fluid className="why-choose-us" style={{ padding: '40px 5%' }}>
      <h2 className="text-center mb-4">Why Choose Us</h2>
      <Row>
        <Col md={4} className="text-right">
          <Zoom>
            <Card className="mb-3 border-0">
              <Card.Body className="text-right">
                <Card.Title className="animated-icon right-aligned">
                  <VerifiedIcon sx={{ fontSize: 40, color: '#1976d2' }} />
                  Quality Assurance
                </Card.Title>
                <Card.Text className="right-aligned">
                  We ensure the highest quality in all our services and products.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-3 border-0">
              <Card.Body className="text-right">
                <Card.Title className="animated-icon right-aligned">
                  <SupportAgentIcon sx={{ fontSize: 40, color: '#1976d2' }} />
                  Customer Support
                </Card.Title>
                <Card.Text className="right-aligned">
                  Our team is available 24/7 to assist you with any inquiries.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="border-0">
              <Card.Body className="text-right">
                <Card.Title className="animated-icon right-aligned">
                  <SecurityIcon sx={{ fontSize: 40, color: '#1976d2' }} />
                  Expert Team
                </Card.Title>
                <Card.Text className="right-aligned">
                  Our team consists of industry experts with years of experience.
                </Card.Text>
              </Card.Body>
            </Card>
          </Zoom>
        </Col>

        <Col md={4} className="text-center">
          <Zoom>
            <Card className="mb-3 border-0">
              <Card.Body>
                <img src={whyUsImage} alt="Why Us" style={{ width: '100%', borderRadius: '10px' }} />
              </Card.Body>
            </Card>
          </Zoom>
        </Col>

        <Col md={4}>
          <Zoom>
            <Card className="mb-3 border-0">
              <Card.Body >
                <Card.Title className="animated-icon left-aligned">
                  <StarIcon className="" sx={{ fontSize: 40, color: '#1976d2' }} />
                  Reliability
                </Card.Title>
                <Card.Text  >
                  We are trusted by many clients for our reliable services.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-3 border-0">
              <Card.Body sx={{rightAlign: '12em'}}>
                <Card.Title className="animated-icon left-aligned">
                  <EmojiObjectsIcon sx={{ fontSize: 40, color: '#1976d2' }} />
                  Sustainability
                </Card.Title>
                <Card.Text >
                  We are committed to environmentally friendly practices.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="border-0">
              <Card.Body>
                <Card.Title className="animated-icon left-aligned">
                  <EmojiObjectsIcon sx={{ fontSize: 40, color: '#1976d2' }} />
                  Innovation
                </Card.Title>
                <Card.Text >
                  We stay ahead with the latest technology and trends.
                </Card.Text>
              </Card.Body>
            </Card>
          </Zoom>
        </Col>
      </Row>
    </Container>
  );
};

export default WhyChooseUs;
