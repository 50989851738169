import React from 'react';
import CustomNavbar from '../Components/CustomNavbar';
import Sandwichspread from '../Components/SandwichSpread';
import Footer from '../Components/Footer';


const SandwichSpread = () => {
  return (
    <>
    <CustomNavbar/>
    <Sandwichspread/>
    <Footer/>  
    
    </>
  );
};

export default SandwichSpread;