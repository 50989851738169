import React, { useState } from 'react';
import './SandwichSpread.css'; // Custom styles

const SandwichSpread = () => {
  const [zoomedImage, setZoomedImage] = useState(null);

  const images = [
    { src: "../images/fc-range/sandwich-spreads/cheese-jalapeno.png", alt: "cheese-jalapeno", title: "cheese-jalapeno" },
    { src: "../images/fc-range/sandwich-spreads/cheezy-dressing.png", alt: "Cheezy Dressing", title: "Cheezy Dressing" },
     
     ];

  const handleImageClick = (imageSrc) => {
    setZoomedImage(imageSrc);
  };

  const handleCloseZoom = () => {
    setZoomedImage(null);
  };

  return (
    <>
      {/* Breadcrumb Section */}
      <section className="breadcrumbs-custom" style={{ marginBottom: '5em' }}>
        <div className="parallax-container" style={{ backgroundImage: `url(images/slide-03.jpg)` }}>
          <div className="breadcrumbs-custom-body parallax-content context-dark">
            <div className="container"  style={{ color: '#333' }}>
              <h2 className="breadcrumbs-custom-title"   >Sandwich Spread</h2>
              <h5 className="breadcrumbs-custom-text"   >
                Foodcoast International - Authentic Freshness of Flavour
              </h5>
            </div>
          </div>
        </div>
        <div className="breadcrumbs-custom-footer">
          <div className="container">
            <ul className="breadcrumbs-custom-path">
              <li><a href="../">Home</a></li>
              <li><a href="/foodcoast">Products</a></li>
              <li className="active">Sandwich Spread</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Image Gallery Section */}
      <section className="section section-sm section-top-0 offset-negative-1">
        <div className="container">
          <h2 className="text-center mb-4">Sandwich Spread</h2>
          <div className="row justify-content-center">
            {images.map((image, index) => (
              <div
                key={index}
                className="col-md-3 col-sm-6 col-6 image-container mb-4"
                style={{
                  background: '#f5f5f5',
                  border: '1px solid #ddd',
                  borderRadius: '8px',
                  height: '300px', // Restored previous height
                  width: '250px', // Decreased width
                  margin: '20px', // Increased margin
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  transition: 'transform 0.3s ease',
                }}
                onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  style={{
                    maxWidth: '90%',
                    maxHeight: '70%',
                    objectFit: 'contain',
                  }}
                  onClick={() => handleImageClick(image.src)}
                />
                <p style={{ marginTop: '5px', fontSize: '14px', color: '#555' }}>
                  {image.title}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Zoom Modal Section */}
      {zoomedImage && (
        <div className="zoom-modal" onClick={handleCloseZoom} style={zoomModalStyle}>
          <img
            src={zoomedImage}
            alt="Zoomed Image"
            style={zoomedImageStyle}
            onClick={handleCloseZoom}
          />
        </div>
      )}
    </>
  );
};

// Styles for the Zoom Modal
const zoomModalStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const zoomedImageStyle = {
  maxWidth: '90%',
  maxHeight: '90%',
  borderRadius: '8px',
  cursor: 'pointer',
};

export default SandwichSpread;
