import React, { useState } from 'react';
import './Capability.css'; // Custom styles

const Capability = () => {
  const [zoomedImage, setZoomedImage] = useState(null); // State to hold the zoomed image

  const images = [
    { src: "../images/FCI/204.jpg", alt: "Image 1", title: " " },
    { src: "../images/FCI/1346.jpg", alt: "Image 2", title: " " },
    { src: "../images/FCI/1356.jpg", alt: "Image 3", title: " " },
    { src: "../images/FCI/1364.jpg", alt: "Image 3", title: " " },
    { src: "../images/FCI/1426.jpg", alt: "Image 3", title: " " },
    { src: "../images/FCI/1435.jpg", alt: "Image 3", title: " " },
    { src: "../images/FCI/1438.jpg", alt: "Image 3", title: " " },
    { src: "../images/FCI/1447.jpg", alt: "Image 3", title: " " },
    { src: "../images/FCI/1455.jpg", alt: "Image 3", title: " " },
    { src: "../images/FCI/1473.jpg", alt: "Image 3", title: " " },
  ];

  // Function to handle image click and open zoomed modal
  const handleImageClick = (imageSrc) => {
    setZoomedImage(imageSrc); // Set the zoomed image when clicked
  };

  // Function to close zoomed image modal
  const handleCloseZoom = () => {
    setZoomedImage(null); // Close the zoomed image modal
  };

  return (
    <>
      {/* Breadcrumb Section */}
      <section className="breadcrumbs-custom" style={{ marginBottom: '5em' }}>
  <div
    className="parallax-container"
    style={{ backgroundImage: `url(images/slide-03.jpg)` }}
  >
    <div className="breadcrumbs-custom-body parallax-content context-dark">
      <div className="container"  style={{ color: '#333' }}>
        <h2 className="breadcrumbs-custom-title">Capability</h2>
        <h5 className="breadcrumbs-custom-text">
          Foodcoast International - Authentic Freshness of Flavour
        </h5>
      </div>
    </div>
  </div>
  <div className="breadcrumbs-custom-footer">
    <div className="container">
      <ul className="breadcrumbs-custom-path">
        <li>
          <a href="../">Home</a>
        </li>
        <li className="active">Capability</li>
      </ul>
    </div>
  </div>
</section>


      {/* Image Gallery Section */}
      <section className="section section-sm section-top-0 offset-negative-1">
        <div className="container">
        <h2 className="text-center mb-4">Capability</h2>
          <div className="row">
            {images.map((image, index) => (
              <div key={index} className="col-md-3 col-sm-6 col-6 text-center mb-4">
                <img
                  src={image.src}
                  alt={image.alt}
                  className="zoomImg"
                  style={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleImageClick(image.src)} // Open zoom on image click
                />
                <p style={{ marginTop: '5px', fontSize: '14px', color: '#555' }}>
                  {image.title}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Zoom Modal Section */}
      {zoomedImage && (
        <div
          className="zoom-modal"
          onClick={handleCloseZoom}
          style={zoomModalStyle}
        >
          <img
            src={zoomedImage}
            alt="Zoomed Image"
            style={zoomedImageStyle}
            onClick={handleCloseZoom}
          />
        </div>
      )}
    </>
  );
};

// Styles for the Zoom Modal
const zoomModalStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const zoomedImageStyle = {
  maxWidth: '90%',
  maxHeight: '90%',
  borderRadius: '8px',
  cursor: 'pointer',
};

export default Capability;
